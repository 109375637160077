import { useState, useEffect } from "react";
import { Loading } from "../..";
import "./Installations.modules.css";
import banner1 from "./assets/001.jpg";
import banner2 from "./assets/002.jpg";
import banner3 from "./assets/003.jpg";
import banner4 from "./assets/004.jpg";
import banner5 from "./assets/005.jpg";
import banner6 from "./assets/006.jpg";
import banner7 from "./assets/007.jpg";
import banner8 from "./assets/008.jpg";
import banner9 from "./assets/009.jpg";
import banner10 from "./assets/010.jpg";
import banner11 from "./assets/011.jpg";
import banner12 from "./assets/012.jpg";
import banner13 from "./assets/013.jpg";
import banner14 from "./assets/014.jpg";
import banner15 from "./assets/015.jpg";
import banner16 from "./assets/016.jpg";
import banner17 from "./assets/017.jpg";
import banner18 from "./assets/018.jpg";
import banner19 from "./assets/019.jpg";
import banner20 from "./assets/020.jpg";
import banner21 from "./assets/021.jpg";
import banner22 from "./assets/022.jpg";
import banner23 from "./assets/023.jpg";
import banner24 from "./assets/024.jpg";
import banner25 from "./assets/025.jpg";
import banner26 from "./assets/026.jpg";
import banner27 from "./assets/027.jpg";
import banner28 from "./assets/028.jpg";
import banner29 from "./assets/029.jpg";
import banner30 from "./assets/030.jpg";
import banner31 from "./assets/031.jpg";
import banner32 from "./assets/032.jpg";
import banner33 from "./assets/033.jpg";
import banner34 from "./assets/034.jpg";
import banner35 from "./assets/035.jpg";
import banner36 from "./assets/036.jpg";

export const Installation = () => {
  const [selectedImage, setSelectedImage] = useState<any | null>(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [carouselOpen, setCarouselOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const images = [
    banner22,
    banner2,
    banner3,
    banner4,
    banner5,
    banner6,
    banner7,
    banner8,
    banner9,
    banner10,
    banner11,
    banner12,
    banner13,
    banner14,
    banner15,
    banner16,
    banner17,
    banner18,
    banner19,
    banner20,
    banner21,
    banner23,
    banner24,
    banner25,
    banner26,
    banner27,
    banner28,
    banner29,
    banner30,
    banner31,
    banner32,
    banner33,
    banner34,
    banner35,
    banner36,
    banner1,
  ];

  const openImage = (image: any, index: any) => {
    setSelectedImage(image);
    setCurrentImageIndex(index);
    setCarouselOpen(true);
  };

  const closeImage = () => {
    setSelectedImage(null);
    setCarouselOpen(false);
  };

  const nextImage = () => {
    if (carouselOpen) {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }
  };

  const prevImage = () => {
    if (carouselOpen) {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === 0 ? images.length - 1 : prevIndex - 1
      );
    }
  };

  useEffect(() => {
    const imagePromises = images.map((imageSrc) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = resolve;
        img.onerror = reject;
        img.src = imageSrc;
      });
    });

    Promise.all(imagePromises)
      .then(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error loading images:", error);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (carouselOpen) {
      setSelectedImage(images[currentImageIndex]);
    }
  }, [currentImageIndex, carouselOpen]);

  useEffect(() => {
    let intervalId: any;

    if (carouselOpen) {
      intervalId = setInterval(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, 3000);
    }
    return () => clearInterval(intervalId);
  }, [carouselOpen, images.length]);

  return (
    <div className="installation-container">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <h2 className="installation-title">INSTALAÇÕES</h2>
          <h2 className="installation-subtitle">
            A Comunidade Terapêutica fica localizada numa área de 7,5ha, rural.
            Tem capacidade para acolher 25 adultos, do sexo masculino, com
            idades entre 18 a 63 anos.
          </h2>
          <div className="photo-gallery">
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Installation ${index + 1}`}
                onClick={() => openImage(image, index)}
              />
            ))}
          </div>
          {selectedImage && (
            <div className="overlay" onClick={closeImage}>
              <div className="modal">
                <div className="modalContent">
                  <img src={selectedImage} alt="Selected Installation" />
                  <button className="close_modal" onClick={closeImage}>
                    X
                  </button>
                  <div className="carousel-indicators">
                    {images.map((_, index) => (
                      <span
                        key={index}
                        className={`indicator ${
                          index === currentImageIndex ? "active" : ""
                        }`}
                        onClick={() => setCurrentImageIndex(index)}
                      ></span>
                    ))}
                  </div>
                  <button
                    className="carousel-prev"
                    onClick={(event) => {
                      prevImage();
                      event.stopPropagation();
                    }}
                  >
                    &lt;
                  </button>
                  <button
                    className="carousel-next"
                    onClick={(event) => {
                      nextImage();
                      event.stopPropagation();
                    }}
                  >
                    &gt;
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
