import React from "react";
import YouTube from "react-youtube";

const YouTubeShort = ({ videoId }: any) => {
  const opts = {
    height: "250",
    width: "450",
  };

  return <YouTube videoId={videoId} opts={opts} />;
};

export default YouTubeShort;
