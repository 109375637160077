import "./home.modules.css";
import banner from "./assets/banner.jpg";
import { Link } from "react-router-dom";

export const Home = () => {
  return (
    <div className="banner">
      <img src={banner} alt="Banner" className="banner-image" />
      <div className="banner-content">
        <h1 className="banner-title">
          Bem-vindo a<br /> Comunidade Terapêutica Cristo Rei PATRE
        </h1>
        <p className="banner-description">
          Nós estamos prontos para caminhar ao seu lado, oferecendo apoio,
          <br /> segurança, profissionalismo e dedicação no seu tratamento.
        </p>
        <Link to="/quemSomos" className="banner-button">
          Saiba mais
        </Link>
      </div>
    </div>
  );
};
