import { useState } from "react";
import "./location.modules.css";
import emailJs from "@emailjs/browser";

export const Location = () => {
  const [locationFullName, setLocationFullName] = useState("");
  const [locationEmailAddress, setLocationEmailAddress] = useState("");
  const [locationMessage, setLocationMessage] = useState("");
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errors, setErrors] = useState<{
    fullName?: string;
    emailAddress?: string;
    message?: string;
  }>({});

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const newErrors: {
      fullName?: string;
      emailAddress?: string;
      message?: string;
    } = {};

    if (locationFullName.trim() === "") {
      newErrors.fullName = "Digite seu nome";
    }

    if (locationEmailAddress.trim() === "") {
      newErrors.emailAddress = "Digite seu email";
    } else if (!isEmailValid(locationEmailAddress)) {
      newErrors.emailAddress = "Email inválido";
    }

    if (locationMessage.trim() === "") {
      newErrors.message = "Digite sua mensagem";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      const templateParams = {
        from_name: locationFullName,
        message: locationMessage,
        email: locationEmailAddress,
      };

      emailJs
        .send(
          "service_o1j6r13",
          "template_rx422xp",
          templateParams,
          "A9ZwqnseLq65PqXxh"
        )
        .then(
          (response) => {
            console.log("Email enviado", response.status, response.text);
            setLocationFullName("");
            setLocationEmailAddress("");
            setLocationMessage("");
            setSuccess(true);
            setError(false);
          },
          (err) => {
            console.log("Error: ", err);
            setError(true);
            setSuccess(false);
          }
        );
    } else {
      setSuccess(false);
      setError(false);
    }
  };

  const isEmailValid = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <>
      <h1 className="location-title">Localização</h1>
      <div className="location-container">
        <div className="container-form">
          <form className="location-form" onSubmit={handleSubmit}>
            <h1 className="location-title">Envie suas dúvidas</h1>

            <input
              className="location-input"
              type="text"
              placeholder="Digite seu nome"
              onChange={(e) => setLocationFullName(e.target.value)}
              value={locationFullName}
            />
            {errors.fullName && (
              <p className="error-message">{errors.fullName}</p>
            )}

            <input
              className="location-input"
              type="text"
              placeholder="Digite seu email"
              onChange={(e) => setLocationEmailAddress(e.target.value)}
              value={locationEmailAddress}
            />
            {errors.emailAddress && (
              <p className="error-message">{errors.emailAddress}</p>
            )}

            <textarea
              className="location-textarea"
              placeholder="Digite sua mensagem..."
              onChange={(e) => setLocationMessage(e.target.value)}
              value={locationMessage}
            />
            {errors.message && (
              <p className="error-message">{errors.message}</p>
            )}
            {success && (
              <p className="success-message">Email enviado com sucesso!</p>
            )}
            {error && (
              <p className="error-message">
                Ocorreu um erro no envio do email.
              </p>
            )}

            <input className="location-button" type="submit" value="Enviar" />
          </form>
        </div>
        <div className="gmap_canvas">
          <iframe
            width="820"
            height="560"
            id="gmap_canvas"
            src="https://maps.google.com/maps?q=28%C2%B052%2756.8%22S+51%C2%B017%2736.5%22W&t=&z=13&ie=UTF8&iwloc=&output=embed"
          ></iframe>
          <a href="https://textcaseconvert.com/"></a>
          <br />
          <a href="https://online-timer.me/"></a>
          <br />
        </div>
      </div>
    </>
  );
};
