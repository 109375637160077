import "./donations.modules.css";
import CopyButton from "./pixButton";
import banrisulIcon from "./assets/banrisul.png";
import sicrediIcon from "./assets/Sicredi.png";
import pixLogo from "./assets/pix-logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import CristoRei from "./assets/CristoRei.png";
import NFG from "./assets/nfg.png";

export const Donations = () => {
  const textToCopy = "03.867.829/0001-84";

  return (
    <div className="donations-container">
      <div className="left-section">
        <h2 className="title">
          <strong>Ajude-nos a transformar vidas!</strong>
        </h2>
        <p className="paragraph">
          A Comunidade Terapêutica PATRE clama por sua generosidade em prol
          daqueles que
          <br /> mais precisam. Nosso compromisso é auxiliar os dependentes
          químicos e suas famílias <br />a conquistarem uma nova chance na vida,
          libertando-os do vício e fornecendo suporte emocional, físico e
          psicológico. Cada doação que você faz se tornar um raio de esperança
          para aquelas pessoas que enfrentam batalhas contra a dependência
          química.
          <br /> Contamos com sua ajuda para continuar nossa missão!
        </p>

        <p className="paragraph-whats">
          <a
            href="https://api.whatsapp.com/send?phone=5554996998885"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon
              icon={faWhatsapp}
              className="social-icon icon-whatsapp"
            />
          </a>
          Dúvidas: 54 - 99699.8885
        </p>
        <img
          src={CristoRei}
          alt="Banrisul Icon"
          className="bank-icon"
          style={{ width: 150 }}
        />
      </div>

      <div className="right-section">
        <img
          src={banrisulIcon}
          alt="Banrisul Icon"
          className="bank-icon"
          style={{ width: 100 }}
        />
        <p>
          <strong>ASSOCIAÇÃO AMOR EXIGENTE DE ANTÔNIO PRADO</strong>
          <br /> CNPJ 03.867.829/0001-84 - MATRIZ <br />
          AG: 0520 C/C: 0602343605
        </p>
        <img
          src={sicrediIcon}
          alt="Banrisul Icon"
          className="bank-icon"
          style={{ width: 80 }}
        />
        <p>
          <strong>ASSOCIAÇÃO AMOR EXIGENTE DE ANTÔNIO PRADO</strong>
          <br /> CNPJ: 03.867.829/0002-65 - FILIAL
          <br />
          AG: 0520 C/C: 0602343605
        </p>
        <img
          src={pixLogo}
          alt="Banrisul Icon"
          className="bank-icon"
          style={{ width: 100, marginTop: 10, marginBottom: 15 }}
        />
        <div>
          <CopyButton text={textToCopy} />
        </div>
        <div style={{ flexDirection: "row", marginTop: 40 }}>
          <img
            src={NFG}
            alt="Banrisul Icon"
            className="bank-icon"
            style={{ width: 120 }}
          />
          <p className="paragraph-whats">
            Indique a PATRE como entidade beneficiada no Programa NFG. Nós
            ganhamos recursos<br></br>para continuar salvando vidas e você
            concorre a muitos prêmios todo mês.
          </p>
          <a
            href="https://nfg.sefaz.rs.gov.br/Cadastro/CadastroNfg_1.aspx"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            saiba mais...
          </a>
        </div>
      </div>
    </div>
  );
};
