import React, { useState } from "react";
import "./preventions.modules.css";
import banner from "./assets/000.png";
import image01 from "./assets/001.png";
import image02 from "./assets/002.png";
import image03 from "./assets/003.png";
import image04 from "./assets/004.png";
import image05 from "./assets/005.png";

export const Prevention = () => {
  const [expandedImage, setExpandedImage] = useState(null);

  const handleCardClick = (index: any) => {
    if (expandedImage === index) {
      setExpandedImage(null);
    } else {
      setExpandedImage(index);
    }
  };

  return (
    <>
      <div className="container-prevention">
        <img className="prevention" src={banner} alt="Imagem de Fundo" />
      </div>
      <div className="container-cards">
        <img
          className={`card ${expandedImage === 0 ? "expanded" : ""}`}
          src={image01}
          alt="Card 1"
          onClick={() => handleCardClick(0)}
        />
        <img
          className={`card ${expandedImage === 1 ? "expanded" : ""}`}
          src={image02}
          alt="Card 2"
          onClick={() => handleCardClick(1)}
        />
        <img
          className={`card ${expandedImage === 2 ? "expanded" : ""}`}
          src={image03}
          alt="Card 3"
          onClick={() => handleCardClick(2)}
        />
        <img
          className={`card ${expandedImage === 3 ? "expanded" : ""}`}
          src={image04}
          alt="Card 4"
          onClick={() => handleCardClick(3)}
        />
        <img
          className={`card ${expandedImage === 4 ? "expanded" : ""}`}
          src={image05}
          alt="Card 5"
          onClick={() => handleCardClick(4)}
        />
      </div>
    </>
  );
};
