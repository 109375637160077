import { useState } from "react";
import "./tratament.modules.css";
import tratamento001 from "./assets/tratamento001.png";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";

export const Tratament = () => {
  const [isOpenAcolhida, setIsOpenAcolhida] = useState(false);
  const [isOpenPeriodo, setIsOpenPeriodo] = useState(false);
  const [isOpenPilares, setIsOpenPilares] = useState(false);
  const [isOpenModalidades, setIsOpenModalidades] = useState(false);
  const [isOpenInstrumentos, setIsOpenInstrumentos] = useState(false);

  const toggleAcolhida = () => {
    setIsOpenAcolhida(!isOpenAcolhida);
  };

  const togglePeriodo = () => {
    setIsOpenPeriodo(!isOpenPeriodo);
  };

  const togglePilares = () => {
    setIsOpenPilares(!isOpenPilares);
  };

  const toggleModalidades = () => {
    setIsOpenModalidades(!isOpenModalidades);
  };

  const toggleInstrumentos = () => {
    setIsOpenInstrumentos(!isOpenInstrumentos);
  };

  return (
    <>
      <img
        className="tratament-header"
        src={tratamento001}
        alt="Imagem de Fundo"
      />
      <h1 className="tratament-title">Tratamento</h1>

      <div className="tratament-container">
        <h1 className="tratament-main-title">
          PROGRAMA TERAPÊUTICO CT PATRE CRISTO REI
        </h1>
        <p className="tratament-description tratament-bold">
          O Programa Terapêutico é baseado no modelo de intervenção psicossocial
          com foco no indivíduo, em sua existência-sofrimento, resgate da
          autonomia e cidadania, combate ao estigma e preconceito e a efetivação
          de um cuidado em saúde alinhado a defesa dos direitos humanos.
        </p>
        <p className="tratament-description tratament-bold">
          Nesse Programa, todos os indivíduos participam ativamente, colaborando
          uns com os outros, partilhando informações, servindo de modelos,
          estabelecendo um sistema de normas e valores definidos e uma
          comunicação aberta nos relacionamentos individuais e grupais.
        </p>
        <div
          style={{
            width: "100%",
            background: "#f2f2f2",
          }}
        >
          <div
            style={{
              width: "100%",
              textAlign: "start",
              padding: "16px",
              cursor: "pointer",
              fontFamily: "PT Sans, sans-serif",
              fontSize: "24px",
              fontWeight: "bold",
              marginTop: "30px",
            }}
            onClick={toggleAcolhida}
          >
            ACOLHIDA{" "}
            <span style={{ verticalAlign: "middle", marginBottom: "5px" }}>
              {isOpenAcolhida ? <BsArrowUp /> : <BsArrowDown />}
            </span>
          </div>
          <div
            style={{
              fontSize: "18px",
              color: "#555",
              padding: "16px",
              fontFamily: "PT Sans, sans-serif",
              textAlign: "justify",
              maxHeight: isOpenAcolhida ? "2000px" : "0",
              opacity: isOpenAcolhida ? 1 : 0,
              transition: "max-height 2.7s ease, opacity 0.3s ease",
              overflow: "hidden",
            }}
          >
            <p>
              A acolhida se dá desde o primeiro contato, sendo informado por um
              profissional acerca do programa, até seu encaminhamento para o
              início do tratamento.
            </p>
            <p>
              Entre em contato pelo fone/whats 54 99699-8885 / 54 99930. 2788
            </p>
          </div>
        </div>

        <div style={{ width: "100%", background: "#f2f2f2" }}>
          <div
            style={{
              width: "100%",
              textAlign: "start",
              padding: "16px",
              cursor: "pointer",
              fontFamily: "PT Sans, sans-serif",
              fontSize: "24px",
              fontWeight: "bold",
              marginTop: "30px",
            }}
            onClick={togglePeriodo}
          >
            PERÍODO DO TRATAMENTO{" "}
            <span style={{ verticalAlign: "middle", marginBottom: "5px" }}>
              {isOpenPeriodo ? <BsArrowUp /> : <BsArrowDown />}
            </span>
          </div>
          <div
            style={{
              fontSize: "18px",
              color: "#555",
              padding: "16px",
              fontFamily: "PT Sans, sans-serif",
              textAlign: "justify",
              maxHeight: isOpenPeriodo ? "2000px" : "0",
              opacity: isOpenPeriodo ? 1 : 0,
              transition: "max-height 2.7s ease, opacity 0.3s ease",
              overflow: "hidden",
            }}
          >
            <p>
              O programa de tratamento da Comunidade Terapêutica PATRE tem uma
              duração recomendada de 09 (nove) a 12 (doze) meses, pois
              acreditamos que esse período é essencial para permitir que nossos
              acolhidos desenvolvam habilidades, ferramentas e recursos sólidos
              para superar a dependência e se manterem saudáveis e positivos no
              longo prazo.
            </p>
            <p>
              Dentro deste período de tempo, algumas etapas estão previstas,
              sendo elas:
            </p>
            <p></p>
            <p>1. Desintoxicação e adaptação (0º mês ao 3º mês);</p>
            <p>2. Conscientização e reformulação (3º mês ao 6º mês);</p>
            <p>3. Reinserção social (6º mês ao 9º mês);</p>
            <p>4. Reforço (9º mês ao 12º mês), se necessário.</p>
            <p>
              É importante ressaltar que a duração do tratamento pode variar de
              acordo com as necessidades individuais de cada paciente, e que
              nossa equipe trabalha em conjunto com os acolhidos para avaliar
              regularmente o progresso e ajustar o plano de tratamento de acordo
              com as necessidades individuais.
            </p>
          </div>
        </div>

        <div style={{ width: "100%", background: "#f2f2f2" }}>
          <div
            style={{
              width: "100%",
              textAlign: "start",
              padding: "16px",
              cursor: "pointer",
              fontFamily: "PT Sans, sans-serif",
              fontSize: "24px",
              fontWeight: "bold",
              marginTop: "30px",
            }}
            onClick={togglePilares}
          >
            PILARES DO TRATAMENTO{" "}
            <span style={{ verticalAlign: "middle", marginBottom: "5px" }}>
              {isOpenPilares ? <BsArrowUp /> : <BsArrowDown />}
            </span>
          </div>
          <div
            style={{
              fontSize: "18px",
              color: "#555",
              padding: "16px",
              fontFamily: "PT Sans, sans-serif",
              textAlign: "justify",
              maxHeight: isOpenPilares ? "2000px" : "0",
              opacity: isOpenPilares ? 1 : 0,
              transition: "max-height 2.7s ease, opacity 0.3s ease",
              overflow: "hidden",
            }}
          >
            <h4 className="tratament-main-title">Espiritualidade</h4>
            <p className="tratament-description">
              A importância da dimensão espiritual e da fé é reconhecida como
              necessária na vida dos seres humanos. No contexto da dependência
              química, este quesito é primordial. A fé no poder superior serve
              de alicerce em busca de um sentido maior para a vida. Dentro da
              comunidade terapêutica é respeitado a individualidade de crenças,
              entendendo a singularidade de cada indivíduo a partir de sua
              história de vida. Deste modo, a instituição propicia despertar o
              interesse pela fé através de textos, dinâmicas e reflexões,
              compreendendo que a busca se dá a partir de estímulos podendo
              promover o desejo do indivíduo.
            </p>
            <h4 className="tratament-main-title">
              Trabalho (atividade prática inclusiva)
            </h4>
            <p className="tratament-description">
              O principal fundamento da atividade prática inclusiva é promover o
              sentimento de pertencimento ao indivíduo, ampliando a consciência
              da importância de cuidar do local onde vive, retomando o senso de
              organização, que por vezes encontra-se perdido.
            </p>
            <h4 className="tratament-main-title">Disciplina</h4>
            <p className="tratament-description">
              São promovidas situações onde o acolhido pode desenvolver o senso
              de organização de um modo amplo. Neste sentido, a disciplina tem
              como objetivo principal contribuir para um modo de vida mais
              saudável. Compreende-se que o aprendizado ocorre através dos
              hábitos diários e a repetição dos mesmos, estes dentro da
              comunidade se caracterizam por: a manutenção da higiene pessoal
              que traz à tona um olhar mais saudável para si, o modo como
              organiza sua cama e seus pertences pessoais são demonstrações de
              evolução da disciplina dentro do tratamento.
            </p>
          </div>
        </div>
        <div style={{ width: "100%", background: "#f2f2f2" }}>
          <div
            style={{
              width: "100%",
              textAlign: "start",
              padding: "16px",
              cursor: "pointer",
              fontFamily: "PT Sans, sans-serif",
              fontSize: "24px",
              fontWeight: "bold",
              marginTop: "30px",
            }}
            onClick={toggleModalidades}
          >
            MODALIDADES DE ATENDIMENTO{" "}
            <span style={{ verticalAlign: "middle", marginBottom: "5px" }}>
              {isOpenModalidades ? <BsArrowUp /> : <BsArrowDown />}
            </span>
          </div>
          <div
            style={{
              fontSize: "18px",
              color: "#555",
              padding: "16px",
              fontFamily: "PT Sans, sans-serif",
              textAlign: "justify",
              maxHeight: isOpenModalidades ? "2000px" : "0",
              opacity: isOpenModalidades ? 1 : 0,
              transition: "max-height 2.7s ease, opacity 0.3s ease",
              overflow: "hidden",
            }}
          >
            <p className="tratament-description">
              <strong>Atendimento Psicológico Individual: </strong> É oferecido
              atendimento psicológico individual semanal para os acolhidos com o
              objetivo de acolher e avaliar suas dificuldades e traumas, tendo
              assim condição de criar vínculos saudáveis, melhorar sua
              autoestima, desenvolver autonomia, favorecendo o seu
              desenvolvimento pessoal.
            </p>
            <p className="tratament-description">
              <strong>Psiquiátrico/Medicamentoso: </strong>Realizado sempre que
              necessário, através da vinculação com a rede pública de Saúde do
              município, ou quando possível, por planos médicos ou particular.
            </p>
            <p className="tratament-description">
              <strong>Orientação Individual: </strong>A orientação
              individualizada acontece constantemente, por considerar que todas
              as intervenções de diferentes áreas se voltam ao cuidado do
              acolhido na qualidade do tratamento. Em especial, os monitores
              possuem o papel constante de orientar de forma individualizada os
              acolhidos, na coletividade do grupo, um olhar singular a cada
              sujeito. Quando necessário, toda a equipe escuta o acolhido
              individualmente, sinalizando e orientando nas diversas situações,
              para uma maior evolução no seu tratamento.
            </p>
            <p className="tratament-description">
              <strong>Atendimento Social: </strong>Quando o acolhido chega a
              comunidade é analisado toda a documentação e são avaliadas ações
              de prevenção, tratamento, socialização e inserção do indivíduo e
              de sua família junto as políticas sociais e a rede de serviços na
              perspectiva de qualidade de vida.
            </p>
            <p className="tratament-description">
              <strong>Atendimento à família: </strong>A família do acolhido é
              atendida, em especial, através das reuniões que acontecem na sede
              da Entidade, reuniões on-line para familiares de outras cidades e
              também nos dias de visita de familiares, onde a equipe se divide e
              faz os atendimentos as famílias. Trata-se de um trabalho
              importante, uma vez que a rede familiar é um dos eixos de
              sustentação do tratamento e o resgate de vínculos e a
              conscientização da família é de suma importância para a efetiva
              qualidade do tratamento.
            </p>
          </div>
        </div>
        <div style={{ width: "100%", background: "#f2f2f2" }}>
          <div
            style={{
              width: "100%",
              textAlign: "start",
              padding: "16px",
              cursor: "pointer",
              fontFamily: "PT Sans, sans-serif",
              fontSize: "24px",
              fontWeight: "bold",
              marginTop: "30px",
            }}
            onClick={toggleInstrumentos}
          >
            INSTRUMENTOS TERAPÊUTICOS{" "}
            <span style={{ verticalAlign: "middle", marginBottom: "5px" }}>
              {isOpenInstrumentos ? <BsArrowUp /> : <BsArrowDown />}
            </span>
          </div>
          <div
            style={{
              fontSize: "18px",
              color: "#555",
              padding: "16px",
              fontFamily: "PT Sans, sans-serif",
              textAlign: "justify",
              maxHeight: isOpenInstrumentos ? "2000px" : "0",
              opacity: isOpenInstrumentos ? 1 : 0,
              transition: "max-height 2.7s ease, opacity 0.3s ease",
              overflow: "hidden",
            }}
          >
            <p className="tratament-description">
              <u>Reunião de 12 Passos</u> – Esta reunião tem como objetivo
              iniciar um processo de mudança no campo espiritual, emocional e
              comportamental através do entendimento e reconhecimento do
              processo de recuperação. Este grupo interfere gradativamente na
              relação do acolhido com seus próprios sentimentos, ajudando a
              amenizá-los, trabalhá-los e resolvê-los, podendo assim levar a uma
              vida tranquila e produtiva.
            </p>
            <p className="tratament-description">
              <u>Reunião de Amor Exigente</u> – Tem como objetivo a revisão de
              sua escala de valores, entendimento e prática dos mesmos em sua
              vida, através da utilização da metodologia do Amor Exigente.
            </p>
            <p className="tratament-description">
              <u>Espiritualidade</u> – Se oportuniza diariamente e também
              semanalmente por um voluntário junto aos acolhidos, um momento de
              espiritualidade onde se desenvolve estudos, leituras e cantos
              espirituais.
            </p>
            <p className="tratament-description">
              <u>
                Reunião de NATA (Núcleo de Apoio ao Toxicômano e Alcoólatra)
              </u>
              – Neste grupo busca-se a expressão do sentimento, auto avaliação,
              apoio mútuo, estudo do tratamento e consequências da dependência
              química.
            </p>
            <p className="tratament-description">
              <u>Reunião de Prevenção à Recaída</u> – Tem como foco a
              conscientização de cada acolhido em suas particularidades sobre os
              fatores que podem levá-lo a uma recaída, é utilizado de textos que
              contribuam para a discussão.
            </p>
            <p className="tratament-description">
              <u>Reunião Matinal</u> – Este grupo configura-se em um espaço
              dedicado a uma revisão objetiva da vida da Comunidade Terapêutica,
              onde se trabalha a honestidade e a cultura, indo de encontro com
              os principais objetivos da CT.
            </p>
            <p className="tratament-description">
              <u>Grupo Filhos de Deus</u> – grupo semanal com uma hora de
              duração, visa a conscientização e aprofundamento espiritual
              espontâneo. Com momentos de perdão, agradecimento e cânticos de
              louvores.
            </p>
            <p className="tratament-description">
              <u>Reunião de PTS</u> – Mensalmente a equipe técnica se reúne e
              seleciona temas, assuntos e vídeos específicos, conforme o
              momento, para cada acolhido se inteirar e entender mais sobre suas
              dificuldades específicas.
            </p>
            <p className="tratament-description">
              <u>Reunião de 1º Grupo</u> – reunião semanal, realizada com os
              acolhidos que estão nos três primeiros meses de tratamento. É uma
              reunião mais acolhedora, realizado por uma psicóloga, tendo como
              objetivo trabalhar o período de adaptação, suas ansiedades e
              desejos de mudanças, podendo se reconhecer no tratamento.
            </p>
            <p className="tratament-description">
              <u>Reunião de 2º Grupo</u> – reunião semanal, coordenada por um
              monitor, com os acolhidos que entram no 3º mês de tratamento até
              sua primeira reinserção social. Tem como objetivo à
              conscientização do processo de recuperação, avaliando seus
              comportamentos a serem modificados orientando de forma singular.
            </p>
            <p className="tratament-description">
              <u>Reunião de 3º Grupo – APROVE</u> – Grupo realizado a partir da
              primeira reinserção social, tem como objetivo principal dar
              direcionamento pós-tratamento. Nessa reunião é entregue o APROVE –
              “Projeto de Vida”, onde os acolhidos analisam os aspectos de suas
              vidas realizando um planejamento de curto, médio e longo prazo,
              com orientação dos membros da equipe.
            </p>
            <p className="tratament-description">
              <u>Grupo Operativo</u> – visa através de técnicas e dinâmicas o
              reconhecimento de fraquezas, anseios, expectativas, frustrações no
              sentido do autoconhecimento, propiciando o desejo pelo novo.
            </p>
            <p className="tratament-description">
              <u>Atividade Prática Inclusiva</u> – ocorre diariamente sob a
              supervisão do monitor e tem como objetivo o desenvolvimento
              interior do acolhido, a reestruturação de horários, princípios e
              cuidados com higiene, limpeza a serem seguidos por toda a vida.
              Existe uma escala de setores, que é modificada semanalmente, onde
              cada acolhido tem a chance de participar de todos os afazeres da
              vida comunitária.
            </p>
            <p className="tratament-description">
              <u>Oficina de Artes</u> – A atividade é considerada, neste
              processo, como um instrumento de resgate de habilidades,
              viabilizando a expressão, a espontaneidade, o conhecimento das
              potencialidades e das limitações.
            </p>
            <p className="tratament-description">
              <u>Atividades Recreativas</u> – Acreditamos ser muito importante
              para a formação de vínculos e para mostrar aos acolhidos o lado
              bom da vida sem o uso de drogas. Dentre as atividades, temos:
              Futebol, Voleibol, Ping-pong, Dinâmicas, Videoterapia, entre
              muitas outras. A comunidade também participa do Torneio da
              Sobriedade, que ocorre a cada 4 meses em uma comunidade
              terapêutica distinta. A PATRE conta com um time de graduados que
              joga futebol e algumas vezes, é levado os acolhidos mais velhos de
              tratamento para participar do mesmo, sempre acompanhados por
              membos da equipe. São três dias de encontro, onde há
              espiritualidade, companheirismo, jogos, entretenimento e diversão.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
