import { Route, Routes, BrowserRouter } from "react-router-dom";
import { DefaultLayout } from "../layouts/DefaultLayout";
import { Prevention } from "../pages/Prevention";
import { Donations } from "../pages/Donations";
import { Home } from "../pages/Home";
import { Installation } from "../pages/Installations";
import { Tratament } from "../pages/Tratament";
import { WhoWeAre } from "../pages/WhoWeAre";
import { Depositions } from "../pages/Depositions";
import { Location } from "../pages/Location";

export const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<DefaultLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/quemSomos" element={<WhoWeAre />} />
          <Route path="/tratamento" element={<Tratament />} />
          <Route path="/instalacao" element={<Installation />} />
          <Route path="/depoimentos" element={<Depositions />} />
          <Route path="/doacoes" element={<Donations />} />
          <Route path="/prevencao" element={<Prevention />} />
          <Route path="/localizacao" element={<Location />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
