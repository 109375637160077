import React, { useState } from "react";
import { FaCopy } from "react-icons/fa";
import "./pix.modules.css";
import qrCode from "./assets/qrCode.jpeg";

const CopyButton = ({ text }: any) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  return (
    <>
      <div className="copy-container">
        <p className="copy-text">{text}</p>
        <button
          className={`copy-button ${isCopied ? "copied" : ""}`}
          onClick={handleCopy}
        >
          <FaCopy className="copy-icon" />
          {isCopied ? "Copiado!" : "Copiar"}
        </button>
      </div>
      <div className="qr-code_pagSeguro">
        <div className="qr-code-margin">
          <img
            src={qrCode}
            className="imagem-personalizada"
            alt="Descrição da Imagem"
            style={{ width: 80 }}
          />
        </div>
        <form
          action="https://pagseguro.uol.com.br/checkout/v2/donation.html"
          method="post"
        >
          <input type="hidden" name="currency" value="BRL" />
          <input type="hidden" name="receiverEmail" value="ctpatre@gmail.com" />
          <input type="hidden" name="iot" value="button" />
          <input
            type="image"
            src="https://stc.pagseguro.uol.com.br/public/img/botoes/doacoes/120x53-doar.gif"
            name="submit"
            alt="Pague com PagBank - é rápido, grátis e seguro!"
          />
        </form>
      </div>
    </>
  );
};

export default CopyButton;
