import { useState, useEffect } from "react";
import "../DefaultLayout/styles.modules.css";
import { Link, Outlet } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faFacebook,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

import "../DefaultLayout/styles.modules.css";

import logo from "../../assets/logo.png";

export const DefaultLayout = () => {
  const [classOn, setClassOn] = useState(false);

  useEffect(() => {
    if (classOn) {
      document.body.classList.add("menu-open");
    } else {
      document.body.classList.remove("menu-open");
    }
  }, [classOn]);

  return (
    <div className="pages">
      <div className="logo">
        <Link to="/">
          <img src={logo} alt="" className="logo-image" />
        </Link>
      </div>
      <header className="headerShow">
        <div className="faixa-amarela"></div>
        <div className="container">
          {!classOn && <span className="menu-label"></span>}
          <div className={`menu-section ${classOn ? "on" : ""}`}>
            <div
              className={`menu-toggle ${classOn ? "menu-toggle-open" : ""}`}
              onClick={() => setClassOn(!classOn)}
            >
              <div className="one"></div>
              <div className="two"></div>
              <div className="three"></div>
            </div>

            <nav>
              <ul>
                <li className="item">
                  <Link to="/" onClick={() => setClassOn(false)}>
                    Home
                  </Link>
                </li>
                <li className="item">
                  <Link to="/quemSomos" onClick={() => setClassOn(false)}>
                    Quem somos
                  </Link>
                </li>
                <li className="item">
                  <Link to="/tratamento" onClick={() => setClassOn(false)}>
                    Tratamento
                  </Link>
                </li>
                <li className="item">
                  <Link to="/instalacao" onClick={() => setClassOn(false)}>
                    Instalações
                  </Link>
                </li>
                <li className="item">
                  <Link to="/depoimentos" onClick={() => setClassOn(false)}>
                    Depoimentos
                  </Link>
                </li>
                <li className="item">
                  <Link to="/prevencao" onClick={() => setClassOn(false)}>
                    Prevenção
                  </Link>
                </li>
                <li className="item">
                  <Link to="/localizacao" onClick={() => setClassOn(false)}>
                    Localização
                  </Link>
                </li>
                <li className="item">
                  <Link to="/doacoes" onClick={() => setClassOn(false)}>
                    Doações
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>
      <Outlet />
      <footer className="footer">
        <div className="footer-left">
          <img src={logo} alt="" className="logo-image-footer" />
        </div>
        <div className="footer-middle">
          <p>
            Linha Almeida, Capela Nossa Senhora da Saúde, 502 - Interior
            <br /> Antônio Prado - RS - Brasil
          </p>
          <p>Fale conosco: 54 99699.8885 - 54 99930.2788</p>
        </div>
        <div className="footer-right">
          <p>Acompanhe as novidades</p>
          <a
            href="https://api.whatsapp.com/send?phone=5554996998885"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon
              icon={faWhatsapp}
              className="social-icon icon-whatsapp"
            />
          </a>
          <a
            href="https://instagram.com/ctpatre?igshid=MzRlODBiNWFlZA=="
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon
              icon={faInstagram}
              className="social-icon icon-instagram"
            />
          </a>
          <a
            href="https://www.facebook.com/patre.antonioprado?mibextid=ZbWKwL"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon
              icon={faFacebook}
              className="social-icon icon-facebook"
            />
          </a>
          <a
            href="mailto:ctpatre@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon
              icon={faEnvelope}
              className="social-icon icon-envelope"
            />
          </a>
        </div>
      </footer>
      <div className="yellow-bar"></div>
    </div>
  );
};
