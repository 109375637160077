import YouTube from "react-youtube";
import "./depositions.modules.css";
import YouTubeShort from "./YouTubeShort";

export const Depositions = () => {
  const youtubeLinks = [
    "https://www.youtube.com/shorts/cX-aWWM6zG4",
    "https://www.youtube.com/shorts/ipvkE-IyGag",
    "https://www.youtube.com/watch?v=Y4x_AkrzB1k&feature=youtu.be",
    "https://www.youtube.com/watch?v=ROgJyvEWcIA",
    "https://www.youtube.com/watch?v=WvBEfH0nnVA",
    "https://www.youtube.com/watch?v=41jA_kSVFAo",
    "https://www.youtube.com/shorts/td0_kh1He0w",
    "https://www.youtube.com/shorts/9YZcBgBsOOc",
  ];

  let numColumns = 3;
  if (window.innerWidth <= 768) {
    numColumns = 2;
  }
  if (window.innerWidth <= 425) {
    numColumns = 1;
  }

  return (
    <div className="video_central">
      <h1 className="title-depositions">Depoimentos</h1>
      <div className="video-container">
        {youtubeLinks.map((link, index) => (
          <div key={index} className="youtube-video">
            {isShort(link) ? (
              <YouTubeShort videoId={getShortId(link)} />
            ) : (
              <div className="video-wrapper">
                <YouTube videoId={getVideoId(link)} />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

function getVideoId(link: any) {
  const url = new URL(link);
  return url.searchParams.get("v") ?? "";
}

function getShortId(link: any) {
  const url = new URL(link);
  return url.pathname.split("/").pop() ?? "";
}

function isShort(link: any) {
  return link.includes("youtube.com/shorts/");
}
