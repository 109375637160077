import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./loading.css";

export const Loading = () => {
  return (
    <div className="loading-container">
      <div className="loading-spinner"></div>
    </div>
  );
};

const Root = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <React.StrictMode>
          <App />
        </React.StrictMode>
      )}
    </>
  );
};

ReactDOM.render(<Root />, document.getElementById("root"));
