import { useState } from "react";
import "./styles.modules.css";
import image1 from "./assets/image1.jpg";
import image2 from "./assets/image2.jpg";
import image3 from "./assets/image3.jpg";
import image4 from "./assets/image4.jpg";
import image5 from "./assets/image5.jpg";
import image6 from "./assets/image6.jpg";

export const WhoWeAre = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleClick = (image: any) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <div className="WhoWeAreContainer">
      <div className="imageContainer">
        <div className="image">
          <img
            src={image1}
            alt="Imagem 1"
            onClick={() => handleClick(image1)}
          />
        </div>
        <div className="image">
          <img
            src={image2}
            alt="Imagem 2"
            onClick={() => handleClick(image2)}
          />
        </div>
        <div className="image">
          <img
            src={image3}
            alt="Imagem 3"
            onClick={() => handleClick(image3)}
          />
        </div>
      </div>

      <div className="textContainer">
        <h1>Quem Somos</h1>
        <p>
          A Comunidade Terapêutica PATRE, localizada em Antônio Prado/RS, é uma
          organização sem fins lucrativos que trabalha para ajudar pessoas que
          lutam contra a dependência química (álcool e drogas) a superar seus
          desafios e recuperar suas vidas.
        </p>
        <p>
          Nossa equipe é formada por profissionais experientes e dedicados,
          incluindo psicólogos, monitores, coordenadores, voluntários e outros
          especialistas em saúde mental e dependência química. Todos
          compartilhamos a mesma paixão e compromisso de ajudar as pessoas a
          encontrar a força e a coragem para superar seus vícios e reconstruir
          suas vidas e suas famílias.
        </p>
        <p>
          Valorizamos uma abordagem completa para o tratamento, isso significa
          que não vemos nossos acolhidos apenas como indivíduos com um problema
          de dependência, mas como seres humanos completos com uma vida
          emocional, física, social e espiritual.
        </p>
        <p>
          Acreditamos que a recuperação é um processo contínuo e estamos
          comprometidos em oferecer suporte constante aos nossos acolhidos após
          o término do tratamento. Estamos aqui para ajudá-lo em todas as etapas
          de sua jornada de recuperação, para que você possa construir uma vida
          feliz, saudável e satisfatória.
        </p>
        <p className="signature">
          <strong>MISSÃO:</strong> Acolher a vulnerabilidade e desenvolver o
          auto suporte.
        </p>
      </div>

      <div className="imageContainer">
        <div className="image">
          <img
            src={image4}
            alt="Imagem 4"
            onClick={() => handleClick(image4)}
          />
        </div>
        <div className="image">
          <img
            src={image5}
            alt="Imagem 5"
            onClick={() => handleClick(image5)}
          />
        </div>
        <div className="image">
          <img
            src={image6}
            alt="Imagem 6"
            onClick={() => handleClick(image6)}
          />
        </div>
      </div>

      {selectedImage && (
        <div className="modal" onClick={closeModal}>
          <div className="modalContent">
            <span className="close" onClick={closeModal}>
              &times;
            </span>
            <img src={selectedImage} alt="Imagem ampliada" />
          </div>
        </div>
      )}
    </div>
  );
};
